const TELEPHONE_LENGTH = 10;
const NUMBER_KEY_CODES = [
    /*Using shift key combination*/ 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
    /*From the numPad*/ 96, 97, 98, 99, 100, 101, 102, 103, 104, 105
];

export function validateEmail(email) {
    return validateRequiredField(email) ||
      (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? '' : 'Veuillez saisir une adresse mail valide')
}

export function validateTel(tel) {
    return (tel.replace(/-/g, '').replace(/\./g, '').replace(/ /g, '').length === TELEPHONE_LENGTH && tel.match(/^0[123456789]{1}[. -]{0,1}[0-9]{2}[. -]{0,1}[0-9]{2}[. -]{0,1}[0-9]{2}[. -]{0,1}[0-9]{2}$/g)) ? '' : 'Veuillez saisir un numéro de téléphone valide (0XXXXXXXXX)'
}

export function validatePassword(password) {
    return (password.match(/^(?=.{8,}$)(?=.*?[A-Z])(?=.*?[0-9]).*$/)) ? '' : 'Votre mot de passe doit contenir au moins 8 caractères dont une majuscule et un chiffre';
}

export function validatePasswordConfirmation(password, passwordConfirmation) {
    return passwordConfirmation === password ? '' : 'Veuillez  renseigner 2 mots de passe identiques'
}

export function validateRequiredField(value) {
    return (value === '' || value === null  || value.length === 0) ? 'Ce champ est requis.' : '';
}

export function validateEntireForm(fields, errors, except) {
    if (typeof except === 'undefined') {
        except = []
    }
    except.push('createdAt')
    except.push('updatedAt')

    if (except.length) {
        for (const property in fields) {
            if(except.indexOf(property) !== -1) {
                delete fields[property]
            }
        }
    }

    return (Object.values(fields).some(field => (field === '' || field === null)) || Object.values(errors).some(error => error !== ''));
}

export function isFormValid(fields, errors, except) {
    if (typeof except === 'undefined') {
        except = []
    }

    if (except.length) {
        for (const property in fields) {
            if(except.indexOf(property) !== -1) {
                delete fields[property]
            }
        }
    }

    for (const field of Object.keys(fields)) {
        if ( (fields[field] === '' || fields[field] === null) ) {
            errors[field] = 'Ce champ est requis.'
        }
    }
    return !(Object.values(fields).some(field => (field === '' || field === null)) || Object.values(errors).some(error => error !== ''));
}

export function allowNumericKeyOnly(keyCode) {
    return ([/*Backspace*/8, /*Tab*/9, /*ArrowLeft*/37, /*ArrowRight*/39, /*Delete*/46, /*NumpadDecimal.*/110, /*Decimal*/190, ...NUMBER_KEY_CODES].indexOf(keyCode) !== -1)
}

export function allowNumericKeyAsDateOnly(input, keyCode) {
    return (input !== '-' && (input === '/' || [/*Backspace*/8, /*Tab*/9, /*ArrowLeft*/37, /*ArrowRight*/39, /*Delete*/46, ...NUMBER_KEY_CODES].indexOf(keyCode) !== -1))
}

export function errorClass(error) {
    return (!error ? '' : 'is-invalid');
}


