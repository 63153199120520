import { toast } from 'react-toastify';

export const ACCESS_DENIED_STATUS_CODE = 403

export function notifySaveSuccess() {
  return toast.success("Enregistrement réussi")
}

export function notifySaveError(errStatus, errorMsg) {
  errorMsg = typeof errorMsg !== "undefined" ? errorMsg : null
  return toast.error(errStatus === ACCESS_DENIED_STATUS_CODE ? "Opération non autorisée." : (errorMsg !== null ? errorMsg : "Echec de l'enregistrement"))
}

export function notifyDeleteSuccess() {
  return toast.success("Suppression réussie")
}

export function notifyDeleteError(errStatus) {
  return toast.error(errStatus === ACCESS_DENIED_STATUS_CODE ? "Opération non autorisée." : "Echec de la suppression")
}

export function notifyCensureHandled() {
  return toast.success("Signalement(s) traité(s) avec succès")
}

export function notifyCensureNotHandled() {
  return toast.success("Avertissement(s) enregistré(s) avec succès")
}

export function notifyReportHandled(actionType) {
  let msg = actionType === 0 ? "Signalement ignoré !" :
              (actionType === 1 ? "L'utilisateur a bien été averti !" :
                (actionType === 2 ? "Profil désactivé avec succès !" : ""))
  return toast.success(msg)
}

export function notifyReportNotHandled(actionType) {
  let msg = actionType === 0 ? "Echec : Signalement non ignoré !" :
              (actionType === 1 ? "Echec de l'avertissement utilisateur !" :
                (actionType === 2 ? "Echec de la désactivation du profil !" : ""))
  return toast.success(msg)
}

export function notifyImgValidationHandled(actionType) {
  let msg = actionType === 0 ? "Photo censurée !" : "Photo validée !"
  return toast.success(msg)
}

export function notifyImgValidationNotHandled(actionType) {
  return toast.success("Erreur de traitement, veuillez réessayer plus tard")
}

export function notifyMailSentSuccess() {
  return toast.success("Mail envoyé")
}

export function notifyValidateDescription($valide) {
  const message = $valide ? "Description validée !" : "Description censurée !"
  return toast.success(message)
}

export function notifyError($message) {
  return toast.error($message)
}
