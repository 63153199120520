import axios from "axios/index";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('tokenApi') || ''}`
  },
  withCredentials: true,
});

export default apiClient;
