import React, {Component} from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm, CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText, CInvalidFeedback, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle,
  CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import UserService from "../../../Services/UserService";
import {toast} from "react-toastify";
import {notifyImgValidationNotHandled, notifyMailSentSuccess} from "../../../Services/NotifyService";
import {validateEmail} from "../../../Services/ValidationService";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showForgotPasswordModal: false,
    }
  }

  render() {
    const setShowForgotPassword = () => {
      this.setState({showForgotPasswordModal: !this.state.showForgotPasswordModal});
    }

    const handleForgotPassword = async () => {
      setShowForgotPassword()
      UserService.forgotPassword(this.state.email)
        .then(() => {
          this.state.email = "";
          notifyMailSentSuccess()
        })
        .catch((err) => {
          notifyImgValidationNotHandled()
        })
    }

    return (
      <div className="c-app c-default-layout flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="8">
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <CForm>
                      <h1>Se connecter</h1>
                      <hr/>
                      <CInputGroup className="mb-3">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-user"/>
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          onChange={(event) => this.setState({email: event.target.value})}
                          type="text" placeholder="Email" autoComplete="email"/>
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-lock-locked"/>
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          onChange={(event) => this.setState({password: event.target.value})}
                          type="password" placeholder="Mot de passe" autoComplete="current-password"/>
                      </CInputGroup>
                      <CRow>
                        <CCol xs="6">
                          <CButton onClick={() => {
                            UserService.logIn(this.state.email, this.state.password).then((res) => {
                              try {
                                const {data: {token, role}} = res.data
                                if (role !== 'ROLE_ADMIN') {
                                  toast.error("Utilisateur non autorisé", {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  });
                                } else if (token) {
                                  localStorage.setItem('tokenApi', token)
                                  window.location.replace('/home')
                                }
                              } catch (e) {
                                throw e
                              }
                            }).catch((err) => {
                              let message = "";
                              switch (err.response.status) {
                                case 401:
                                  message = 'Email ou mot de passe incorrect'
                                  break;
                                default:
                                  message = 'Une erreur est survenue'
                                  break;
                              }
                              toast.error(message, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                            })
                          }} color="primary" className="px-4">Se connecter</CButton>
                        </CCol>
                        <CCol xs="6" className="text-right">
                          <CButton color="link" className="px-0" onClick={() => setShowForgotPassword()}>Mot de passe oublié ?</CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
                <CCard className="text-white bg-primary py-5 d-md-down-none" style={{width: '44%'}}>
                  <CCardBody className="text-center">
                    <div>
                      <img src={process.env.REACT_APP_LOGO_WITH_NAME_PATH} style={{height: '10rem'}} alt="logo"/>
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>

        <CModal
          show={this.state.showForgotPasswordModal}
          onClose={() => setShowForgotPassword()}
          color="dark"
        >
          <CModalHeader closeButton>
            <CModalTitle>Mot de passe oublié ?</CModalTitle>
          </CModalHeader>
          <CModalBody>
            Un email vous sera envoyé pour réinitialiser votre mot de passe ?
            <CFormGroup>
              <CInput id="email" className="form-control"
                      invalid={this.state.email === '' || validateEmail(this.state.email) !== ''}
                      value={this.state.email}
                      onChange={(evt) => {this.setState({email: evt.target.value})}}
              />
              <CInvalidFeedback>{validateEmail(this.state.email)}</CInvalidFeedback>
            </CFormGroup>
          </CModalBody>
          <CModalFooter>
            <CButton color="secondary" onClick={() => setShowForgotPassword()}>Annuler</CButton>
            <CButton color="dark" disabled={this.state.email === '' || validateEmail(this.state.email) !== ''} onClick={() => handleForgotPassword()}>Envoyer</CButton>
          </CModalFooter>
        </CModal>
      </div>
    )
  }
}

export default Login
