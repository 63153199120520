import apiClient from './api.client';
class UserService {

    logIn(email, password) {
      return apiClient.post('/api/login', {username: email, password: password, fromBackOffice: true });
    }

    logOut() {
      localStorage.removeItem('tokenApi')
      window.location.replace('/login')
    }

    getUsers(params) {
        return apiClient.get('/api/users', {params}).then((response) => {
            return response.data;
        })
    }

    getUser(user) {
        return apiClient.get('/api/user/' + user.id).then((response) => {
            return response.data;
        })
    }

    getLoggedUser() {
        return apiClient.get('/api/loggedUser').then((response) => {
            return response.data;
        })
    }

    saveUser(user, id) {
      return (id === null) ? apiClient.post('/api/user', user) : apiClient.put('/api/user/' + id, user)
    }

    setUserStatus(id, status) {
      return apiClient.put('/api/user/' + id + '/' + status, {'userId': id, 'status': status})
    }

    getDashStats() {
      return apiClient.get('/api/dashStats').then((response) => {
        return response.data;
      })
    }

    fetchCities(q) {
      return apiClient.get('/api/cities?q=' + q);
    }

    fetchCountries(q) {
      return apiClient.get('/api/countries?q=' + q);
    }

    getSubscriptions() {
      return apiClient.get('/api/subscriptions');
    }

    subscribe(subscriptionId) {
      return apiClient.post('/api/createSubscriptionSession/' + subscriptionId);
    }

    createSubscription(subscriptionId, sessionId) {
      return apiClient.post('/api/createUserSubscription/' + sessionId + '/' + subscriptionId);
    }

    forgotPassword(email) {
      return apiClient.post('/api/forgot-password', { email: email });
    }
}

export default new UserService();
